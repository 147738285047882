import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import type { Banner } from "@interfaces/Banner";
import trans from "@routes";

interface Props {
  bannersList: Banner[];
}

const Carousel: React.FC<Props> = ({ bannersList }) => {
  const { t, i18n } = useTranslation('translation');
  
  let currentLang = i18n.language;

  if(currentLang === "fr"){
	currentLang = "ca";
  }


  const [currentIndex, setCurrentIndex] = useState(0);

  const nextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % bannersList.length);
  };

  const prevSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + bannersList.length) % bannersList.length);
  };

  // Automatically switch to the next slide every 5 seconds
  useEffect(() => {
    const interval = setInterval(() => {
      nextSlide();
    }, 5000);

    return () => {
      clearInterval(interval);
    };
  }, [currentIndex]);
  
  return (
    <div className="relative  mt-11 mb-11">
      <div className="relative h-40 rounded-lg">
        {bannersList.map((currentBanner: any, index: number) => (
          <a key={index} href={currentBanner["link-" + currentLang]} className={`duration-700 ease-in-out ${index === currentIndex ? '' : 'opacity-0 hidden'}`}>
            <div >
              <img src={currentBanner['imagen-desktop-' + currentLang]} className="absolute md:block w-full rounded shadow -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2" alt="image banner" />
              <img src={currentBanner['imagen-mobile-' + currentLang]} width="355" height="165" className="absolute md:hidden block w-full rounded shadow -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2" alt="image banner" />
            </div>
          </a>
        ))}
      </div>

      {/* Slider controls */}
      <button type="button" className="absolute top-0 left-0 z-30 flex items-center justify-center h-full px-4 cursor-pointer group focus:outline-none" onClick={prevSlide}>
        <span className="inline-flex items-center justify-center w-10 h-10 rounded-full bg-black group-hover:bg-black/50 group-focus:ring-black group-focus:outline-none">
          <i className="fa-solid fa-chevron-left text-[#FFD100]"></i>
          <span className="sr-only">Previous</span>
        </span>
      </button>
      <button type="button" className="absolute top-0 right-0 z-30 flex items-center justify-center h-full px-4 cursor-pointer group focus:outline-none" onClick={nextSlide}>
        <span className="inline-flex items-center justify-center w-10 h-10 rounded-full bg-black group-hover:bg-black/50 group-focus:ring-black group-focus:outline-none">
          <i className="fa-solid fa-chevron-right text-[#FFD100]"></i>
          <span className="sr-only">Next</span>
        </span>
      </button>
    </div>
  );
};

export default Carousel;
